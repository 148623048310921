import { useAuthenticationStore } from '@/stores/authentication';
export default class Authentication {
  constructor(dataSource, app) {
    this.$auth = useAuthenticationStore();
    this.$dataSource = dataSource;
    this.$app = app;
  }

  getIsAuthenticated() {
    return this.$auth.getIsAuthenticated;
  }

  getSession() {
    const session = this.$app.$cookiz.get('session-zentric');

    if (!session) return false;
    const permissions = JSON.parse(localStorage.getItem('permissions') || '[]');
    session.user.permission_related = permissions;

    return session;
  }

  setSession(session) {
    this.$auth.setSession(session);
    const Permissions = session.user?.permission_related ?? [];

    localStorage.setItem('permissions', JSON.stringify(Permissions) || '[]');
    const sessionForCookie = structuredClone(session);
    delete sessionForCookie.user.permission_related;
    this.$app.$cookiz.set('session-zentric', sessionForCookie, {
      path: '/',
      sameSite: 'none',
      secure: true,
    });
  }

  softResetSession() {
    this.$auth.resetSession();
    this.$app.$cookiz.removeAll();
  }

  resetSession() {
    this.$auth.resetSession();
    // list all keys in local storage
    const keys = Object.keys(localStorage);

    // remove all keys from local storage except dataTables/ZTable, white-label and special storage
    keys.forEach((value, index, array) => {
      if (
        !value.includes('DataTables') &&
        !value.includes('ZTABLE') &&
        !value.includes('white-label') &&
        !value.startsWith('release-') &&
        !value.includes('preserve')
      ) {
        localStorage.removeItem(value);
      }
    });
    this.$app.$cookiz.removeAll();
  }

  async refreshToken(session) {
    const { refresh_token: refreshToken, token } = session || {};

    const refreshTime = +localStorage.getItem('refresh_time');
    const currentTime = Date.now();

    // Si el tiempo de refresco es menor a 4 minutos no se refresca el token
    if (refreshTime && currentTime - refreshTime < 60_000 * 4) {
      return {
        token,
        refresh_token: refreshToken,
      };
    }

    if (!refreshToken || !token) return false;

    const endpoint = `users/token/refresh/`;
    const payload = {
      refresh: refreshToken,
    };

    const response = await this.$dataSource.postData(payload, endpoint);
    localStorage.setItem('refresh_time', Date.now());

    const { access = '', refresh = '' } = response || {};

    // La respuesta debe contener el token de acceso y de refresco, de lo contrario se retorna falso
    if (!access || !refresh) return false;

    return {
      token: access,
      refresh_token: refresh,
    };
  }

  async refreshUserConfig(session) {
    const { user, token } = session || {};
    if (!user || !token) return false;
    // Se intenta traer el user_config_related para actualizar
    const endpoint = `users/users/${user.id}/`;
    const { code = 0, data = {} } =
      (await this.$dataSource.getData(endpoint, token)) || {};

    if (code !== 3) return false;

    return data;
  }

  async refreshCurrentUserConfig() {
    const {
      token,
      refresh_token: refresh,
      user,
    } = this.$app.$authentication.getSession();
    const validUserConfig = await this.$app.$authentication.refreshUserConfig({
      user,
      token,
    });

    if (!validUserConfig) return;

    const newSession = {
      user: validUserConfig,
      token,
      refresh_token: refresh,
    };

    this.$app.$authentication.setSession(newSession);
  }

  isStaff() {
    const permissions = JSON.parse(localStorage.getItem('permissions') || '[]');
    const implementer = permissions.includes('user.payroll-implementer');
    const support = permissions.includes('user.payroll-support');
    const dev = permissions.includes('user.payroll-developer');
    return !!implementer || !!support || !!dev;
  }

  isSupport() {
    const permissions = JSON.parse(localStorage.getItem('permissions') || '[]');
    const support = permissions.includes('user.payroll-support');
    return !!support;
  }

  isOwner() {
    const permissions = JSON.parse(localStorage.getItem('permissions') || '[]');
    const owner = permissions.includes('user.account-owner');
    return !!owner;
  }

  isWorky() {
    const { user } = this.$app.$cookiz.get('session-zentric') || {};
    return !!user?.is_worky;
  }

  isWorkyLite() {
    const { user } = this.$app.$cookiz.get('session-zentric') || {};
    return !!user?.worky_lite;
  }

  isMasterUser() {
    const { user = {} } = this.$app.$cookiz.get('session-zentric') || {};
    return +user?.id === 1;
  }

  getUserConfigByVariable(variable) {
    const defaultValue = {};
    const { user = null } = this.$app.$cookiz.get('session-zentric') || {};
    if (!user) return defaultValue;
    const { userconfig_related: UC = [] } = user || {};
    if (!UC.length) return defaultValue;
    return UC.find((item) => item?.variable === variable) || {};
  }

  getCurrentUser() {
    const { user } = this.$app.$cookiz.get('session-zentric') || {};
    return user;
  }

  getUserPhoto() {
    const { user } = this.$app.$cookiz.get('session-zentric') || {};
    return user?.photo || '';
  }
}
